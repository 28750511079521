<template>
  <div>
    <div class="columns">
      <div class="column">
        <p class="title has-text-grey-light is-6">VIC Inspected</p>
      </div>
      <div class="column is-narrow">
        <p class="title is-6">
          <span v-if="inspected" class="has-text-warning">{{
            formatDate(inspected)
          }}</span>
          <span class="has-text-success" v-else>No</span>
        </p>
      </div>
    </div>
    <div class="box" v-if="inspected">
      <table class="table is-fullwidth is-striped">
        <thead>
          <tr>
            <td>Date of test</td>
            <td>Status</td>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in items" :key="index">
            <td>
              <p>{{ formatDate(item.vicDate) }}</p>
            </td>
            <td>
              <p
                class="has-text-weight-bold"
                :class="[
                  item.vicResult ? 'has-text-success' : 'has-text-danger'
                ]"
              >
                {{ item.vicResult ? 'Pass' : 'Fail' }}
              </p>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { formatDate } from '@/utils/date.utils.ts'
export default {
  name: 'AutoCheckVIC',
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  computed: {
    items() {
      return this.data?.vehicleIDCheck1
    },
    inspected() {
      return this.data?.vehicleIDCheck1[0]?.vicDate
    }
  },
  methods: {
    formatDate
  }
}
</script>
